import { useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Header'
import Sidebar from './Sidebar'
import Home from './pages/Home'
import Licenses from './pages/Licenses'

import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
import Institutions from './pages/institutions';
import Customers from './pages/Customers';
Amplify.configure(awsExports);




function App({ signOut, user }) {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)
  const [institutionList, setInstitutionList] = useState([]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }

  function newInstitutionList(newlist){
    console.log("refreshed newInstitution from App", newlist)
    setInstitutionList(newlist)
    localStorage.setItem('institutionList', JSON.stringify(newlist));
  }


  return (
    <Router>
      <div className='grid-container'>
        <Header OpenSidebar={OpenSidebar}/>
        <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
        <Routes>
          <Route path='/' element={<Home newInstitutionList={newInstitutionList}/>} />
          <Route path="/licenses" element={<Licenses/>} />
          <Route path="/institutions" element={<Institutions institutionList={institutionList}/>} />
          <Route path="/customers" element={<Customers institutionList={institutionList}/>} />
          
        </Routes>
      </div>
    </Router>

  )
}

export default withAuthenticator(App, { hideSignUp: false });