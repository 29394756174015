import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ResponseTable = ({ endpointstatus }) => {
    if (!endpointstatus || !endpointstatus.Outputs || endpointstatus.Outputs.length === 0) {
        console.log("No data available",endpointstatus)
        return <p>No data available</p>;
      }
  // Parse the JSON response
  //const response = JSON.parse(endpointstatus);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Output Key</TableCell>
            <TableCell>Output Value</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {endpointstatus.Outputs.map((output, index) => (
            <TableRow key={index}>
              <TableCell>{output.OutputKey}</TableCell>
              <TableCell>{output.OutputValue}</TableCell>
              <TableCell>{output.Description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResponseTable;
