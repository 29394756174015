import React, { useState } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { API } from 'aws-amplify';
import LoadingButton from '@mui/lab/LoadingButton';



function CreateEndpoint({refreshLicense}) {
  //const classes = useStyles();
  const [new_database_name, setNew_database_name] = useState('licensedb');
  const [new_username, setNew_username] = useState('');
  const [new_user_password, setNew_user_password] = useState('')
  const [loadingData, setLoadingData] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingData(true)
    const data = {
      body: {
        "new_database_name": new_database_name,
        "new_username": new_username,
        "new_user_password": new_user_password
      },
    };
    
    console.log(data);

    try {
      const response = await API.post('admindashboardAPI', '/endpoint/create', data);
      console.log('API call successful:', response);
      refreshLicense();
      setLoadingData(false)
    } catch (error) {
      console.error('Error:', error);
      setLoadingData(false)
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center" sx={{backgroundColor:"white" , padding:"20px"}}>
        <Grid item xs={4}>
        <TextField
            label="Client Identifier"
            type="new_database_name"
            value={new_database_name}
            onChange={(e) => setNew_database_name(e.target.value)}
            fullWidth
            //sx={{ color:"white", input: { color: 'white' },label: { color: 'white' } }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Username (no Capital)"
            type="new_username"
            value={new_username}
            onChange={(e) => setNew_username(e.target.value)}
            fullWidth
            //sx={{ color:"white", input: { color: 'white' } ,label: { color: 'white' }}}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Password"
            type="new_user_password"
            value={new_user_password}
            onChange={(e) => setNew_user_password(e.target.value)}
            fullWidth
            //sx={{ color:"white", input: { color: 'white' } ,label: { color: 'white' }}}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <LoadingButton loading={loadingData} 
          //variant="outlined" 
          type="submit" 
          variant="contained" 
          color="primary">
            Create Endpoints
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default CreateEndpoint