import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const SubscriptionsTable = ({ subscriptionsList }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Institution Name</TableCell>
            <TableCell>License Type</TableCell>
            <TableCell>Discount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptionsList.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.institution_name}</TableCell>
              <TableCell>{row.license_type}</TableCell>
              <TableCell>{row.discount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubscriptionsTable;
