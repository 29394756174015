import React, { useState, useEffect, useMemo } from 'react';
import { API } from 'aws-amplify';
import 
{ BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsFillBellFill}
 from 'react-icons/bs'
 import { CiDollar } from "react-icons/ci";
 import 
 { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } 
 from 'recharts';
 import { MdNumbers } from "react-icons/md";


function Home({newInstitutionList}) {
  const [institutionList, setInstitutionList] = useState([]);
  const [totalcount, setTotalcount] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(0)
  const [revenue, setRevenue] = useState(0)

  const institutions = useMemo(async () => {
    try {
      
      const response = await API.get('admindashboardAPI', '/institutions/list');
      console.log("institutions",response)
      setInstitutionList(response)
      newInstitutionList(response)
      return response || [];
    } catch (error) {
      console.error('Error fetching institutions:', error);
      return [];
    }
  }, []);

  const totalcases = useMemo(async () => {
    try {
      const response = await API.get('admindashboardAPI', '/license/cases/count');
      //console.log(response)
      let totalCount = 0;
      for (let i = 0; i < response.length; i++) {
          totalCount += response[i].count;
      }

      console.log(totalCount)

      setTotalcount(totalCount)
      return response || [];
    } catch (error) {
      console.error('Error fetching institutions:', error);
      return [];
    }
  }, []);

    const data = [
        {
          name: 'Customer A',
          SiteA: 4000,
          SiteB: 2400,
          amt: 2400,
        },
        {
          name: 'Customer B',
          SiteA: 3000,
          SiteB: 1398,
          amt: 2210,
        },
        {
          name: 'Customer C',
          SiteA: 2000,
          SiteB: 9800,
          amt: 2290,
        },
        {
          name: 'Customer D',
          SiteA: 2780,
          SiteB: 3908,
          amt: 2000,
        },
        {
          name: 'Customer E',
          SiteA: 1890,
          SiteB: 4800,
          amt: 2181,
        },
        {
          name: 'Customer F',
          SiteA: 2390,
          SiteB: 3800,
          amt: 2500,
        },
        {
          name: 'Page G',
          SiteA: 3490,
          SiteB: 4300,
          amt: 2100,
        },
      ];

      const Cases = [
        {
          name: '11/15',
          US: 4000
          
        },
        {
          name: '11/16',
          US: 3000
        },
        {
          name: '11/17',
          US: 2000
        },
        {
          name: '11/18',
          US: 2780
        },
        {
          name: '11/19',
          US: 1890
        },
        {
          name: '11/20',
          US: 2390
        },
        {
          name: '11/21',
          US: 3490
        },
      ];
     

  return (
    <main className='main-container'>
        <div className='main-title'>
            <h3>DASHBOARD</h3>
        </div>
        <div className='main-cards'>


        <div className='card'>
                <div className='card-inner'>
                    <h3>Institutions</h3>
                    <BsPeopleFill className='card_icon'/>
                </div>
                <h1>{institutionList.length}</h1>
            </div>

            <div className='card'>
                <div className='card-inner'>
                    <h3>Total Cases</h3>
                    <MdNumbers className='card_icon'/>
                </div>
                <h1>{totalcount}</h1>
            </div>

            <div className='card'>
                <div className='card-inner'>
                    <h3>Current Month count</h3>
                    <BsFillBellFill className='card_icon'/>
                </div>
                <h1>{currentMonth}</h1>
            </div>

            <div className='card'>
                <div className='card-inner'>
                    <h3>Current Month Revenue</h3>
                    <CiDollar className='card_icon'/>
                </div>
                <h1>{revenue}</h1>
            </div>


        </div>

        <div className='charts'>
            <ResponsiveContainer width="100%" height="100%">
            <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="SiteA" fill="#82ca9d" />
                <Bar dataKey="SiteB" fill="#8884d8" />
                
                </BarChart>
            </ResponsiveContainer>

            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                width={500}
                height={300}
                data={Cases}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Line type="monotone" dataKey="SiteB" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                <Line type="monotone" dataKey="US" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>

        </div>
    </main>
  )
}

export default Home