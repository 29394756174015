import React, { useState, useEffect, useMemo } from 'react';
import {
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsFillBellFill,
} from "react-icons/bs";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { API } from 'aws-amplify';

import { LuRefreshCw } from "react-icons/lu";
import Addlicense from "../components/addlicense/Addlicense";
import Listlicenses from "../components/listlicenses/Listlicenses";
import AddSubscription from "../components/addSubscription/AddSubscriptions";
import LicenseTable from "../components/listlicenses/licensetable";
import { Divider, IconButton } from '@mui/material';



function Licenses() {
  const [licenseList, setLicenseList] = useState([]);
  const [refreshLicense, setRefreshLicense] = useState(0);


  const licenses = useMemo(async () => {
    try {
      const response = await API.get('admindashboardAPI', '/license/list');
      setLicenseList(response)
      console.log(response)
      return response || [];
    } catch (error) {
      console.error('Error fetching licenses:', error);
      return [];
    }
  }, [refreshLicense]);

  return (
    <main className="main-container">
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1 style={{ marginRight: "auto" }}>Manage Licenses</h1>

      </div>
      <div className="main-title">
        <h3>Add License:</h3>
      </div>
      <Addlicense refreshLicense={()=>setRefreshLicense(refreshLicense+1)}/>
      
      
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1 style={{ marginRight: 'auto' }}>Licenses Information</h1>
        <IconButton sx={{color:"white"}}        
        onClick={()=>setRefreshLicense(refreshLicense+1)} 
        aria-label="Refresh">
          <LuRefreshCw />
        </IconButton>
      </div>

      <div>
          <LicenseTable licenses={licenseList} />
      </div>

      <div>
      <Divider/>
      </div>


    </main>
  );
}

export default Licenses;
