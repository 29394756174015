import React, { useState } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { API } from 'aws-amplify';



function Addlicense({refreshLicense}) {
  //const classes = useStyles();
  const [textValue, setTextValue] = useState('');
  const [moneyValue, setMoneyValue] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      body: {
        license_type: textValue,
        license_value: parseFloat(moneyValue),
      },
    };
    console.log(data);

    try {
      const response = await API.post('admindashboardAPI', '/license/add', data);
      console.log('API call successful:', response);
      refreshLicense();
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
        <TextField
            label="License Name"
            type="license_type"
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
            fullWidth
            sx={{ color:"white", input: { color: 'white' },label: { color: 'white' } }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Value"
            type="license_value"
            value={moneyValue}
            onChange={(e) => setMoneyValue(e.target.value)}
            fullWidth
            sx={{ color:"white", input: { color: 'white' } ,label: { color: 'white' }}}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button type="submit" variant="contained" color="primary">
            Add License
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default Addlicense