import React, { useState, useEffect, useMemo } from "react";

import Addlicense from "../components/addlicense/Addlicense";
import Listlicenses from "../components/listlicenses/Listlicenses";
import { API } from "aws-amplify";
import { LuRefreshCw } from "react-icons/lu";
import AddSubscription from "../components/addSubscription/AddSubscriptions";

import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Divider, IconButton } from '@mui/material';

import SubscriptionsTable from "../components/SubscriptionsTable/SubscriptionsTable";
import CreateEndpoint from "../components/createEndpoint/CreateEndpoint";

function Institutions({institutionList}) {
  const [licenseList, setLicenseList] = useState([]);
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [refreshLicense, setRefreshLicense] = useState(0);

useEffect(() => {
  console.log(institutionList)

}, [institutionList])


  const institutions = useMemo(async () => {
    try {
      const response = await API.get(
        "admindashboardAPI",
        "/subscriptions/list"
      );
      console.log(response);
      setSubscriptionsList(response);
      return response || [];
    } catch (error) {
      console.error("Error fetching institutions:", error);
      return [];
    }
  }, []);
  
  const licenses = useMemo(async () => {
    try {
      const response = await API.get('admindashboardAPI', '/license/list');
      setLicenseList(response)
      console.log(response)
      return response || [];
    } catch (error) {
      console.error('Error fetching licenses:', error);
      return [];
    }
  }, [refreshLicense]);

  return (
    <main className="main-container">
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1 style={{ marginRight: "auto" }}>Add Subscriptions</h1>

      </div>
      {/* <CreateEndpoint/> */}
      <AddSubscription licenseList={licenseList} />

      <div className="main-title">
        <h3>Subscriptions</h3>
        <IconButton
          sx={{ color: "white" }}
          onClick={() => setRefreshLicense(refreshLicense + 1)}
          aria-label="Refresh"
        >
          <LuRefreshCw />
        </IconButton>
      </div>

      <SubscriptionsTable subscriptionsList={subscriptionsList} />
    </main>
  );
}

export default Institutions;
