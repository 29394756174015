import React, { useState, useEffect, useMemo } from "react";
import Addlicense from "../components/addlicense/Addlicense";
import Listlicenses from "../components/listlicenses/Listlicenses";
import { API } from "aws-amplify";
import { LuRefreshCw } from "react-icons/lu";
import AddSubscription from "../components/addSubscription/AddSubscriptions";

import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from "@mui/material";
import { Divider, IconButton } from '@mui/material';

import SubscriptionsTable from "../components/SubscriptionsTable/SubscriptionsTable";
import CreateEndpoint from "../components/createEndpoint/CreateEndpoint";
import ResponseTable from "../components/CheckStatusTable";
import { LoadingButton } from "@mui/lab";






function Customers({ institutionList }) {
  const [selectedInstitution, setSelectedInstitution] = useState('');
  const [selectedDocumentationStatus, setSelectedDocumentationStatus] = useState('');
  const [institutions, setInstitutions] = useState([])
  const [endpointstatus, setEndpointstatus] = useState("")
  const [licenseList, setLicenseList] = useState([]);
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [refreshSubscriptions, setRefreshSubscriptions] = useState(0);
  const [loadingData, setLoadingData] = useState(false)



  useEffect(() => {
    setInstitutions(JSON.parse(localStorage.getItem('institutionList')))
  }, [institutionList])

  useEffect(() => {
    console.log("selectedInstitution", selectedInstitution)
    checkStatus(selectedInstitution)
  }, [selectedInstitution])

  const checkStatus = async (selectedInstitution) => {
    try {

      const data = {
        body: {
          'stackname': 'Stack' + selectedInstitution,
        },
      };
      console.log(data);


      const response = await API.post('admindashboardAPI', '/cloudformation/status', data);
      console.log("checkStatus", response)
      setEndpointstatus(response)
      return response || [];
    } catch (error) {
      console.error('Error fetching institutions:', error);
      return [];
    }
  }


  const Getinstitutions = useMemo(async () => {
    try {

      const response = await API.get('admindashboardAPI', '/institutions/list');
      console.log("institutions", response)
      setInstitutions(response)
      return response || [];
    } catch (error) {
      console.error('Error fetching institutions:', error);
      return [];
    }
  }, [refreshSubscriptions]);



  function deleteEndpoint() {
    const data = {
      body: {
        "username": selectedInstitution
      },
    };
    API.del('admindashboardAPI', '/endpoint/delete',data)
      .then(response => {
        console.log(response);

      })
      .catch(error => {
        console.log(error);
      });
  }



  return (
    <main className="main-container">
      <Box  >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1 style={{ marginRight: "auto" }}>Manage Customers</h1>

        </div>
        <CreateEndpoint />
      </Box>

      <Box  >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1 style={{ marginRight: "auto" }}>Monitor Endpoint Creation</h1>

        </div>
        <Box>
          <InputLabel sx={{ color: 'White' }}>Institution:</InputLabel>
          <Select
            value={selectedInstitution}
            onChange={(e) => setSelectedInstitution(e.target.value)}

            sx={{ color: 'White', width: "97%" }}
          >
            {institutions?.map((institution, index) => (
              <MenuItem key={index} value={institution.identifier}>
                {institution.name}
              </MenuItem>
            ))}
          </Select>
          <IconButton
            sx={{ color: "white" }}
            onClick={() => setRefreshSubscriptions(refreshSubscriptions + 1)}
            aria-label="Refresh"
          >
            <LuRefreshCw />
          </IconButton>
        </Box>
        <LoadingButton loading={loadingData}
          //variant="outlined" 
          type="submit"
          variant="contained"
          color="primary">
          Create Endpoints
        </LoadingButton>
        <LoadingButton loading={loadingData}
          //variant="outlined" 
          onClick={()=> deleteEndpoint()}
          type="submit"
          variant="contained"
          color="primary">
          Delete Endpoints
        </LoadingButton>

        <Box>

        <Box  >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1 style={{ marginRight: "auto" }}>Monitor Endpoint Creation</h1>

        </div>
        <Box>
          <InputLabel sx={{ color: 'White' }}>Documentation:</InputLabel>
          <Select
            value={selectedDocumentationStatus}
            onChange={(e) => setSelectedDocumentationStatus(e.target.value)}

            sx={{ color: 'White', width: "97%" }}
          >
            {["PENDING", "SIGNED", "REJECTED"]?.map((DoucmentStatus, index) => (
              <MenuItem key={index} value={DoucmentStatus}>
                {DoucmentStatus}
              </MenuItem>
            ))}
          </Select>
          <IconButton
            sx={{ color: "white" }}
            onClick={() => setRefreshSubscriptions(refreshSubscriptions + 1)}
            aria-label="Refresh"
          >
            <LuRefreshCw />
          </IconButton>
        </Box>
        <LoadingButton loading={loadingData}
          //variant="outlined" 
          type="submit"
          variant="contained"
          color="primary">
          Change Documentation Status
        </LoadingButton>


        </Box>
          <ResponseTable endpointstatus={endpointstatus} />
        </Box>
      </Box>




    </main>
  );
}

export default Customers;
