import React, { useState, useEffect, useMemo } from 'react';
import { API } from 'aws-amplify';
import { Grid, TextField, Button, Select, MenuItem, InputLabel } from '@mui/material';


const AddSubscription = ({ licenseList}) => {
  const [selectedInstitution, setSelectedInstitution] = useState('');
  const [institutionList, setInstitutionList] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState('');
  //const [licenseList, setLicenseList] = useState([]);
  const [discount, setDiscount] = useState(0);

  const institutions = useMemo(async () => {
    try {
      const response = await API.get('admindashboardAPI', '/institutions/list');
      console.log(response)
      setInstitutionList(response)
      return response || [];
    } catch (error) {
      console.error('Error fetching institutions:', error);
      return [];
    }
  }, []); 




  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      institution: selectedInstitution,
      license: selectedLicense,
      discount: parseFloat(discount),
    };
    console.log('API data:', data);
    try {
      const response = await API.post('admindashboardAPI', '/subscriptions', { body: data });
      console.log('API call successful:', response);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel sx={{color:'White'}}>Institution:</InputLabel>
        <Select
          value={selectedInstitution}
          onChange={(e) => setSelectedInstitution(e.target.value)}
          fullWidth
          sx={{color:'White'}}
        >
          {institutionList.map((institution, index) => (
            <MenuItem key={index} value={institution.identifier}>
              {institution.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <InputLabel sx={{color:'White'}}>License:</InputLabel>
        <Select
          value={selectedLicense}
          onChange={(e) => setSelectedLicense(e.target.value)}
          fullWidth
          sx={{color:'White'}}
        >
          {licenseList.map((license, index) => (
            <MenuItem key={index} value={license.license_type}>
              {`${license.license_type} ${license.license_value}`}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
      <InputLabel sx={{color:'White'}}>Discount:</InputLabel>
        <TextField
        sx={{ color:"white", input: { color: 'white' } ,label: { color: 'white' }}}
          //label="Discount"
          type="number"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Grid>
    </Grid>
  </form>
  );
};

export default AddSubscription;
